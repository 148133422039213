#swiper_container{
   width: 100%;
   height: 70vh;
   border-radius: 20px;
}

#SwiperSlide-scroll {
    display: flex;
    justify-content: center;
 
}
#SwiperSlide-scroll img{
    width: 100%;
object-fit: cover;
}

.loadingBanner{
    width: 100%;
   height: 70vh;
   border-radius: 20px;
    background: linear-gradient(90deg, #e8e6e6, #fff, #fff);
    background-size: 400% 400%;
    animation: gradientMove 1s linear infinite;
    -webkit-animation: gradientMove 1s linear infinite;
}
.BannerContain{
    padding: 0% 10%;
    padding-top: 5rem;
}
@media (max-width: 1024px){
    .BannerContain{
        padding: 0% 3%;
        padding-top: 5rem;
    }
    #swiper_container{
    height: 40vh;
    border-radius: 20px;
    
}
     .loadingBanner{
        height: 40vh;
        border-radius: 20px;
    }
}
@media (max-width: 900px){
    .BannerContain{
        padding: 0% 3%;
        padding-top: 2rem;
    }
    #swiper_container{
    height: 30vh;
    border-radius: 20px;
    
    }
     .loadingBanner{
        height: 30vh;
        border-radius: 20px;
    }
}

@media (max-width: 600px){
    .BannerContain{
        padding: 0% 3%;
        padding-top: 1rem;
    }
    #swiper_container{
        height: 23vh;
        border-radius: 20px;
        
        }
         .loadingBanner{
            height: 23vh;
            border-radius: 20px;
        }
}